import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ServiceWorkerUpdateListener } from './serviceWorkerUpdateListener';
import store from './reducers';
import Routes from './Routes';
import './styles/app.scss';

import UpdateWaiting from './components/UpdateWaiting';

const App = () => {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [swListener, setSwListener] = useState({});

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const initSW = async () => {
        const listener = new ServiceWorkerUpdateListener();
        setSwListener(listener);
        listener.onupdateinstalling = (installingEvent) => {
          console.log('SW installed', installingEvent);
        };
        listener.onupdatewaiting = (waitingEvent) => {
          console.log('new update waiting', waitingEvent);
          setUpdateWaiting(true);
        };
        listener.onupdateready = () => {
          console.log('updateready event');
          window.location.reload();
        };
        navigator.serviceWorker.getRegistration().then((reg) => {
          listener.addRegistration(reg);
          setRegistration(reg);
        });
        return listener;
      };
      const listener = initSW();
      return () => listener.removeEventListener();
    }
  }, []);

  const handleUpdate = () => {
    swListener.skipWaiting(registration.waiting);
  };

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
      <UpdateWaiting
        updateWaiting={updateWaiting}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};

export default App;
