import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { IoNotificationsSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import styles from './noticiations-bell.module.scss';

const NotificationsBell = ({ color = '#FFFFFF' }) => {
  const { notificationReducer } = useSelector((state) => state);
  const { notifications } = notificationReducer;
  const [notReadNotifications, setNotReadNotifications] = useState(false);

  useEffect(() => {
    const bool = notifications.some((notification) => !notification.markedAsRead);
    setNotReadNotifications(bool);
  }, [notifications]);

  return (
    <div className={styles.bell}>
      <NavLink to="/notifications">
        {notReadNotifications && <div className={styles.dot} />}
        <IoNotificationsSharp size={40} color={color} />
      </NavLink>
    </div>
  );
};

export default NotificationsBell;
