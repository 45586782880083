import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../actions/auth';
import EmailResetPwdForm from '../../components/form/AuthForm/EmailResetPwdForm';
import styles from './forgot-password.module.scss';

import logo from '../../assets/images/logo.svg';
import truck from '../../assets/images/truck.svg';

const ForgotPassword = () => {
  const authReducer = useSelector((d) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/home');
  }, [authReducer.user]);

  function submitForgot(data) {
    forgotPassword(dispatch, data).then((res) => {
      if (res?.status === 200) {
        setMessage(
          'Un email permettant de changer votre mot de passe vous a été envoyé.',
        );
      }
    });
  }

  return (
    <div className={styles['forgot-password']}>
      <div className={styles.truck}>
        <div className={styles.container}>
          <img alt="logo" src={logo} />
          <img alt="truck" src={truck} />
        </div>
      </div>
      <div className={styles.container}>
        <h1>Mot de passe oublié</h1>
        <div>
          <p>
            Après validation vous recevrez un email vous permettant de changer
            de mot de passe.
          </p>
          <div className={styles.form}>
            <EmailResetPwdForm
              submit={submitForgot}
              signinLink="/"
              succeedMessage={message}
              errorMessage={authReducer.error}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
