import axios from 'axios';
import { AUTH_ERROR } from './types';

const API_URL = process.env.REACT_APP_API_URL;

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  const isErrorOnLogin = error.config.url.includes('auth/login');

  if (type !== AUTH_ERROR && error.response !== undefined && error.response.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }

    if (localStorage.getItem('id')) {
      localStorage.removeItem('id');
    }
    // window.location.href = '/';
  } else if (!isErrorOnLogin && error.response.status === 401) {
    window.location.href = '/';
    localStorage.removeItem('agency');
    localStorage.removeItem('id');
    localStorage.removeItem('token');
  }
  if (type) {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export async function postData(errorType, url, dispatch, data, isAuthReq) {
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  let error = null;
  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}

export async function postFormData(errorType, isAuthReq, url, dispatch, data) {
  const requestUrl = API_URL + url;
  const config = {
    timeout: 1000 * 60,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let res = null;
  let error = null;

  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}

export async function putFormData(errorType, isAuthReq, url, dispatch, data) {
  const requestUrl = API_URL + url;
  const config = {
    timeout: 1000 * 60,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let res = null;
  let error = null;

  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.put(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}

export async function getData(errorType, url, dispatch, isAuthReq) {
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  let error = null;
  if (isAuthReq) {
    const token = localStorage.getItem('token');
    config.headers = { Authorization: `${token}` };
  }
  const agency = localStorage.getItem('agency');
  if (agency) config.headers.agency = agency;

  try {
    res = await axios.get(requestUrl, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}

export async function putData(errorType, url, dispatch, data, isAuthReq = true) {
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  let error = null;
  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.put(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, error, errorType);
  }
  return res?.status === 200 ? res : error;
}

export function deleteData(errorType, url, dispatch, isAuthReq) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.delete(requestUrl, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}
