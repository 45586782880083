import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { MdOutlineLogout } from 'react-icons/md';
import { RiEdit2Fill } from 'react-icons/ri';
import { AiFillCamera } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { logout, updateProfilePicture } from '../../../actions/auth';
import styles from './profile.module.scss';

const Profile = () => {
  const dispatch = useDispatch();
  const { authReducer } = useSelector((store) => store);
  const { user } = authReducer;
  const inputPicture = useRef();

  const renderProfilePicture = () => {
    const token = localStorage.getItem('token').replace('JWT ', '');
    const url = `${process.env.REACT_APP_API_URL}/files/public/${user?.picture?.path}?token=${token}`;
    return (
      <div
        onClick={() => inputPicture.current.click()}
        className={styles.profilePicture}
        style={user?.picture?.path ? { backgroundImage: `url(${url})` } : {}}
      >
        <div className={styles.editPicture}>
          <AiFillCamera size={18} />
        </div>
        {!user?.picture?.path && (
          <p>
            {user.profile.firstName[0]}
            {user.profile.lastName[1]}
          </p>
        )}
      </div>
    );
  };

  const handleChangePicture = (file) => {
    const formData = new FormData();
    formData.append('picture', file);
    updateProfilePicture(dispatch, formData);
  };

  return (
    <div className={styles.profile}>
      <input
        ref={inputPicture}
        type="file"
        onChange={(e) => handleChangePicture(e.target.files[0])}
        style={{ display: 'none' }}
      />
      <div className={styles.user}>
        <div className={styles.content}>
          <NavLink className={styles.edit} to="edit">
            <RiEdit2Fill size={28} />
          </NavLink>
          {renderProfilePicture()}
          <h2>{user.profile.firstName} {user.profile.lastName}</h2>
          <h4>{user.agency.name}</h4>
          <p>Email<span> {user.email}</span></p>
          <p>Adresse<span> {user?.address || '---'}</span></p>
          <p>Téléphone<span> {user?.phone || '---'}</span></p>
        </div>
        <button onClick={() => logout(dispatch)}>
          <span>Se déconnecter</span>
          <span className="icon">
            <MdOutlineLogout size={18} />
          </span>
        </button>
      </div>
      <div className={styles.driverLicense}>
        <h3>Permis de conduire</h3>
        <p>Mise à jour</p>
        <p>N°<span> {user?.license?.numero || '---'}</span></p>
        <p>Délivrance<span> {user?.license?.deliveryDate || '---'}</span></p>
        <p>Catégorie<span> {user?.license?.category || '---'}</span></p>
        <p>Fin de validité<span> {user?.license?.expiration || '---'}</span></p>
        <p>Délivré par <span> {user?.license?.delivery || '---'}</span></p>
        <NavLink to="/profile/license">
          <button>Mettre à jour mon permis</button>
        </NavLink>
      </div>
    </div>
  );
};

export default Profile;
