import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { signIn } from '../../actions/auth';
import SignInForm from '../../components/form/AuthForm/SignInForm';

import logo from '../../assets/images/logo.svg';
import truck from '../../assets/images/truck.svg';

import styles from './signin.module.scss';

const SignIn = () => {
  const authReducer = useSelector((d) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/home');
  }, [authReducer.user]);

  function submit(data) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.signin}>
      <div className={styles.truck}>
        <div className={styles.container}>
          <img alt="logo" src={logo} />
          <img alt="truck" src={truck} />
        </div>
      </div>
      <div className={styles.container}>
        <h1>Me connecter</h1>
        <SignInForm
          forgotPwdLink="/forgot-password"
          errorMessage={authReducer.error}
          submit={submit}
        />
      </div>
    </div>
  );
};

export default SignIn;
