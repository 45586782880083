import React from 'react';
import { NavLink, useLocation, Outlet } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';
import BottomNav from '../../components/BottomNav';
import NotificationsBell from '../../components/NotificationsBell';
import styles from './account.module.scss';

const Profile = () => {
  const location = useLocation();

  return (
    <div className={styles.profile}>
      <div className={styles.container}>
        <div className={styles.header}>
          <NavLink to={/profile\/.*/.test(location.pathname) ? '/profile' : '/home'}>
            <BsArrowLeftShort size={40} />
          </NavLink>
          { location.pathname === '/profile' && (
            <h1>Profil</h1>
          )}
          { location.pathname === '/profile/license' && (
            <h1>Mon permis</h1>
          )}
          { location.pathname === '/profile/edit' && (
            <h1>Mes infos</h1>
          )}
          <div className={styles.notifications}>
            <NotificationsBell color="#05004E" />
          </div>
        </div>
        <Outlet />
        <BottomNav />
      </div>
    </div>
  );
};

export default Profile;
