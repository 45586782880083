import {
  AUTH_SIGNIN,
  AUTH_SIGNUP,
  AUTH_GET_PROFILE,
  AUTH_PUT_PROFILE,
  AUTH_PUT_LICENSE,
  AUTH_SET_AGENCY,
  AUTH_UPDATE_PROFILE_PICTURE,
  AUTH_DELETE_PROFILE_PICTURE,
  AUTH_LOADING,
  AUTH_LOGOUT,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  user: null,
  userAgency: null,
  isLoading: [],
  error: null,
};

let isErrorOnLogin;
let errorMessage;

// eslint-disable-next-line default-param-last
const authReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case AUTH_SIGNIN:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('id', action.payload.user._id);

      updatedState = {
        ...state,
        user: action.payload.user,
        isLoading: state.isLoading?.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case AUTH_SIGNUP:
      updatedState = { ...state, isLoading: false, error: null };
      break;
    case AUTH_GET_PROFILE:
    case AUTH_UPDATE_PROFILE_PICTURE:
    case AUTH_DELETE_PROFILE_PICTURE:
      updatedState = {
        ...state,
        user: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case AUTH_PUT_PROFILE:
    case AUTH_PUT_LICENSE:
      updatedState = {
        ...state,
        user: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== action.type),
        error: null,
      };
      break;
    case AUTH_SET_AGENCY:
      updatedState = {
        ...state,
        userAgency: action.payload,
      };
      break;
    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      localStorage.removeItem('agency');

      updatedState = { ...state, user: null };
      break;
    case AUTH_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case AUTH_RESET_ERROR:
      updatedState = { ...state, error: null };
      break;
    case AUTH_ERROR:
      isErrorOnLogin = action.payload.config.url.includes('auth/login');
      errorMessage = 'Vous n\'êtes pas autoriser à accéder à ce contenu';
      if (isErrorOnLogin) {
        errorMessage = 'Adresse e-mail et/ou mot de passe incorrects.';
      } else if (!isErrorOnLogin && action.payload.data.error === 'That email address is already in use.') {
        errorMessage = 'Un compte est déjà associé à cette adresse e-mail.';
      } else if (!isErrorOnLogin && action.payload.data.error === 'Your request could not be processed as entered. Please try again.') {
        errorMessage = 'Aucun compte n\'est déjà associé à cette adresse e-mail.';
      } else if (!isErrorOnLogin && action.payload.data.error === 'Password reset token is invalid or has expired.') {
        errorMessage = 'Le mot de passe a déjà été modifié.';
      } else if (!isErrorOnLogin && action.payload.data.error === 'Can\'t find user for this email') {
        errorMessage = 'Aucun compte ne correspond à cette adresse email.';
      }
      updatedState = { ...state, error: errorMessage };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default authReducer;
