import React from 'react';
import DriversLicenseForm from '../../../components/form/DriversLicenseForm';
import styles from './license.module.scss';

const License = () => (
  <div className={styles.license}>
    <DriversLicenseForm />
  </div>
);

export default License;
