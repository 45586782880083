import {
  format,
} from 'date-fns';
import { fr } from 'date-fns/locale';

const get = (obj = {}, path) => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

const formatDate = (date) => format(new Date(date), 'dd.MM.yyyy', { locale: fr });

export {
  formatDate,
  get,
  chunk,
};
