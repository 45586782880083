import React from 'react';
import {
  format,
  formatDistanceToNow,
} from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { fr } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeftShort } from 'react-icons/bs';
import { updateNotification } from '../../actions/notifications';
import styles from './notifications.module.scss';

const Notifications = () => {
  const dispatch = useDispatch();
  const { notificationReducer } = useSelector((store) => store);
  const navigate = useNavigate();
  const { notifications } = notificationReducer;
  const getDate = (date) => {
    const distance = Date.now() - new Date(date).getTime();
    const strDate = format(new Date(date), 'dd.MM.yyyy', { locale: fr });
    const strDistance = formatDistanceToNow(new Date(date), { addSuffix: true, locale: fr });
    return distance < (3600 * 24 * 1000) ? strDistance : strDate;
  };

  return (
    <div className={styles.notifications}>
      <div className={styles.container}>
        <div className={styles.header}>
          <a onClick={() => navigate(-1)}>
            <BsArrowLeftShort size={40} />
          </a>
          <h1>Notifications</h1>
        </div>
        {notifications.filter((notification) => !notification.markedAsRead).length > 0 && (
          <>
            <h3>Non lues</h3>
            <ul>
              {notifications.filter((notification) => !notification.markedAsRead)
                .sort((a, b) => new Date(a) - new Date(b) < 0 ? 1 : -1)
                .map((notification) => (
                  <li
                    key={`notification-${notification._id}`}
                    className={styles.notification}
                    onClick={() => updateNotification(dispatch, { _id: notification._id })}
                  >
                    <div>
                      <div className={styles.dot} />
                      <p>{notification.text}</p>
                      <p className={styles.date}>{getDate(notification.triggerAt)}</p>
                    </div>
                  </li>
                ))}
            </ul>
          </>
        )}
        {notifications.filter((notification) => notification.markedAsRead).length > 0 && (
          <>
            <h3>Lues</h3>
            <ul>
              {notifications.filter((notification) => notification.markedAsRead)
                .sort((a, b) => new Date(a) - new Date(b) < 0 ? 1 : -1)
                .map((notification) => (
                  <li className={`${styles.notification} ${styles.read}`} key={`notification-${notification._id}`}>
                    <div>
                      <p>{notification.text}</p>
                      <p className={styles.date}>{getDate(notification.triggerAt)}</p>
                    </div>
                  </li>
                ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
