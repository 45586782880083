import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from './input-text.module.scss';

const InputText = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  defaultValue = '',
  className,
  handleChange,
  maxlength,
  disabled,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({
      field: {
        // name,
        value = '',
        ref,
        onChange,
        onBlur,
      },
    }) => {
      function handlePressEnter(e) {
        if (e.key === 'Enter' && e.target) {
          e.target.blur();
        }
      }

      const inputProps = {};

      if (maxlength) {
        inputProps.maxLength = maxlength;
      }

      return (
        <div
          onKeyUp={(e) => handlePressEnter(e)}
          className={
          inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}`
            : `${className ? styles[className] : ''} ${styles['container-input']}`
        }
        >
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <input
            ref={ref}
            disabled={disabled}
            value={value}
            onBlur={onBlur}
            onChange={(e) => {
              if (handleChange) handleChange(e.target.value);
              onChange(e.target.value);
            }}
            className={styles.input}
            placeholder={placeholder}
            {...inputProps}
          />
        </div>
      );
    }}
  />

);

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputText;
