import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getNotifications } from '../../actions/notifications';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    getNotifications(dispatch);
  }, [location]);

  return (
    <>
      {children}
    </>
  );
};

export default Layout;
