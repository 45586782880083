import React from 'react';
import { NavLink } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import { BsPersonFill } from 'react-icons/bs';
import { ImClock2 } from 'react-icons/im';
import styles from './bottom-nav.module.scss';

const BottomNav = () => (
  <div className={styles.footer}>
    <ul>
      <li><NavLink to="/home"><AiFillHome /></NavLink></li>
      <li><NavLink to="/profile"><BsPersonFill /></NavLink></li>
    </ul>
    <ul>
      <li><NavLink to="/timetable"><ImClock2 /></NavLink></li>
    </ul>
  </div>
);

export default BottomNav;
