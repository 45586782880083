import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { BsCheck } from 'react-icons/bs';
import { get, formatDate } from '../../../utils';

import { AUTH_PUT_LICENSE } from '../../../actions/types';
import { updateLicenseUser } from '../../../actions/auth';

import {
  InputText,
  InputFormatPattern,
  ErrorField,
} from '../../../lib/HooksFormFields';
import Loader from '../../Loader';
import FileUploader from '../../FileUploader';
import styles from './drivers-license-form.module.scss';

const DriversLicenseForm = ({ handleSuccess }) => {
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((store) => store.authReducer);
  const [file, setFile] = useState();

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (!user?.license) return;
    reset({
      numero: user?.license?.numero || '',
      category: user?.license?.category || '',
      deliveryDate: user?.license?.deliveryDate || '',
      expiration: user?.license?.expiration || '',
      delivery: user?.license?.delivery || '',
    });
  }, [user]);

  function handleChangeFile(f) {
    setFile(f);
  }

  async function onSubmit(data) {
    if (user) {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (file) formData.append('document', file);

      const res = await updateLicenseUser(dispatch, formData, user._id);
      if (res.status === 200 && handleSuccess) handleSuccess(res.data.user);
    }
  }

  return (
    <div className={styles.form}>
      <div className={styles.col}>
        <div className={`${styles['container-field']} ${styles.licenseImg}`}>
          {user.license?.document && (
            <div className={styles.licenseImgInfos}>
              <p>{user.license.document.name}</p>
              <p>
                Mise à jour le{' '}
                {formatDate(new Date(user.license.document.updatedAt))}
              </p>
            </div>
          )}
          <FileUploader
            handleChangeFile={(f) => handleChangeFile(f)}
            buttonText="Uploader un nouveau scan"
            isLoading={false}
            file={file}
            className="dashed"
          />
        </div>
        <div className={styles['container-field']}>
          <InputText
            name="numero"
            control={control}
            required
            label="N°"
            inline
            widthLabel="90px"
            rules={{
              required: 'Veuillez renseigner le numéro de permis',
            }}
          />
        </div>
        <div className={styles['container-field']}>
          <InputText
            name="category"
            control={control}
            required
            label="Catégorie"
            inline
            widthLabel="90px"
            rules={{
              required: 'Veuillez renseigner la catégorie.',
            }}
          />
        </div>
        <div className={styles['container-field']}>
          <InputFormatPattern
            name="deliveryDate"
            label="Délivrance"
            inline
            control={control}
            placeholder={formatDate(new Date())}
            maxlength={8}
            rules={{
              required: 'Veuillez renseigner la date de délivrance.',
              pattern: {
                value: /(\d{2,2}).(\d{2,2}).(\d{4,4})/,
                message: 'Veuillez renseigner une date de délivrance au format ##.##.####',
              },
            }}
            widthLabel="90px"
            format="##.##.####"
          />
        </div>
        <div className={styles['container-field']}>
          <InputFormatPattern
            name="expiration"
            label="Fin de validité"
            inline
            control={control}
            placeholder={formatDate(new Date())}
            maxlength={8}
            rules={{
              required: 'Veuillez renseigner la date de fin de validité.',
              pattern: {
                value: /(\d{2,2}).(\d{2,2}).(\d{4,4})/,
                message: 'Veuillez renseigner une date de fin de validité au format ##.##.####',
              },
            }}
            widthLabel="90px"
            format="##.##.####"
          />
        </div>
        <div className={styles['container-field']}>
          <InputText
            name="delivery"
            control={control}
            required
            label="Délivré par"
            inline
            widthLabel="90px"
            rules={{
              required:
                'Veuillez indiquer par qui a été délivré le permis de conduire.',
            }}
          />
        </div>
      </div>
      <div className={styles.containerSubmit}>
        {!!Object.keys(errors).length
          && ['numero', 'category', 'deliveryDate', 'expiration', 'delivery']
            .map((key) =>
              get(errors, key) ? (
                <div className={styles.errorMessage}>
                  <ErrorField
                    key={`error-${key}`}
                    message={get(errors, key)?.message || ''}
                  />
                </div>
              ) : null)
            .filter((d) => d)[0]}
        <button onClick={handleSubmit(onSubmit)}>
          {isLoading.includes(AUTH_PUT_LICENSE) ? (
            <span className="icon loader">
              <Loader size={14} className="secondary" />
            </span>
          ) : (
            <span className="icon">
              <BsCheck size="24px" color="#A8C825" />
            </span>
          )}
          <span>Valider </span>
        </button>
      </div>
    </div>
  );
};

export default DriversLicenseForm;
