import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { BsCheck } from 'react-icons/bs';
import { get } from '../../../utils';

import { AUTH_PUT_PROFILE } from '../../../actions/types';
import { updateProfile } from '../../../actions/auth';

import {
  InputText,
  InputFormatNumber,
  Textarea,
  ErrorField,
} from '../../../lib/HooksFormFields';
import Loader from '../../Loader';
import styles from './profile-form.module.scss';

const DriversLicenseForm = ({ handleSuccess }) => {
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((store) => store.authReducer);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (!user?.license) return;
    reset({
      profile: {
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
      },
      address: user?.address || '',
      phone: user?.phone || '',
    });
  }, [user]);

  async function onSubmit(data) {
    const res = await updateProfile(dispatch, data);
    if (res.status === 200 && handleSuccess) handleSuccess(res.data.user);
  }

  return (
    <div className={styles.form}>
      <div className={styles.col}>
        <div className={styles['container-field']}>
          <InputText
            name="profile.firstName"
            control={control}
            required
            label="Prénom"
            inline
            widthLabel="90px"
            rules={{
              required: 'Veuillez renseigner votre prénom',
            }}
          />
        </div>
        <div className={styles['container-field']}>
          <InputText
            name="profile.lastName"
            control={control}
            required
            label="Nom"
            inline
            widthLabel="90px"
            rules={{
              required: 'Veuillez renseigner votre nom',
            }}
          />
        </div>
        <div className={styles['container-field']}>
          <Textarea
            name="address"
            control={control}
            required
            label="Adresse"
            inline
            widthLabel="90px"
            rules={{
              required: 'Veuillez renseigner votre adresse',
            }}
          />
        </div>
        <div className={styles['container-field']}>
          <InputFormatNumber
            name="phone"
            label="Téléphone"
            inline
            control={control}
            widthLabel="90px"
            format="## ## ## ## ##"
          />
        </div>
      </div>
      <div className={styles.containerSubmit}>
        {!!Object.keys(errors).length
          && ['profile.firstName', 'profile.lastName', 'address']
            .map((key) =>
              get(errors, key) ? (
                <div className={styles.errorMessage}>
                  <ErrorField
                    key={`error-${key}`}
                    message={get(errors, key)?.message || ''}
                  />
                </div>
              ) : null)
            .filter((d) => d)[0]}
        <button onClick={handleSubmit(onSubmit)}>
          {isLoading.includes(AUTH_PUT_PROFILE) ? (
            <span className="icon loader">
              <Loader size={14} className="secondary" />
            </span>
          ) : (
            <span className="icon">
              <BsCheck size="24px" color="#A8C825" />
            </span>
          )}
          <span>Valider </span>
        </button>
      </div>
    </div>
  );
};

export default DriversLicenseForm;
