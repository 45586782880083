import React from 'react';
import styles from './update-waiting.module.scss';

const UpdateWaiting = ({ updateWaiting, handleUpdate }) => {
  if (!updateWaiting) return <></>;
  return (
    <div className={styles.toastUpdate}>
      <p>Nouvelle version disponible !</p>
      <button className="secondary" onClick={handleUpdate}>Mettre à jour</button>
    </div>
  );
};

export default UpdateWaiting;
