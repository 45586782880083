import {
  NOTIFICATIONS_GET,
  NOTIFICATION_PUT,
  NOTIFICATION_LOADING,
  NOTIFICATION_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  notifications: [],
  isLoading: [],
  error: null,
};

const notificationReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  const updateNotifications = state.notifications || [];
  const notificationIndex = (updateNotifications || []).findIndex(
    (p) => p._id === action.payload?.notification?._id,
  );
  switch (action.type) {
    case NOTIFICATIONS_GET:
      updatedState = {
        ...state,
        notification: null,
        notifications: action.payload.notifications,
        isLoading: state.isLoading?.filter((d) => d !== NOTIFICATIONS_GET),
        error: null,
      };
      break;
    case NOTIFICATION_PUT:
      if (typeof notificationIndex === 'number') {
        updateNotifications[notificationIndex] = action.payload.notification;
      }
      updatedState = {
        ...state,
        notification: action.payload.notification,
        notifications: updateNotifications.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
        ),
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter(
          (d) => d !== `NOTIFICATION_PUT-${action.payload.notification._id}`,
        ),
        error: null,
      };
      break;
    case NOTIFICATION_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case NOTIFICATION_ERROR:
      // eslint-disable-next-line no-case-declarations
      let message = action?.payload?.data?.error;
      if (message === 'That email address is already in use.') {
        message = 'Cette adresse email est déjà associée à un compte';
      } else if (message === 'Unauthorized to create an notification with this role') {
        message = 'Vous n\'êtes pas autoriser à assigner ce rôle';
      }
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default notificationReducer;
