import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useForm, useController } from 'react-hook-form';
import TimeKeeper from 'react-timekeeper';
import { IoIosAdd } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';
import { FiChevronRight } from 'react-icons/fi';
import { IoCalendarOutline } from 'react-icons/io5';
import { updateDateDeliveryRound } from '../../../actions/deliveryRounds';

import { InputNumber } from '../../../lib/HooksFormFields';
import Loader from '../../Loader';

import styles from './deliveryround-today-form.module.scss';

const DeliveryRoundTodayForm = ({
  day,
  isLoading,
  setSelectedDate,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    watch,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      start: day.expectedStart,
      breakHours: day.expectedBreakHours,
      end: day.expectedEnd,
      target: 0,
    },
  });
  const [step, setStep] = useState(1);
  const watchStart = watch('start');
  const watchEnd = watch('end');
  const watchBreakHours = watch('breakHours');

  const onSubmit = async (data) => {
    let start = null;
    if (typeof data.start === 'string') {
      start = data.start;
    } else if (typeof data?.start?.hour === 'number' && typeof data?.start?.minute) {
      start = `${data.start.hour < 10 ? `0${data.start.hour}` : data.start.hour}:${data.start.minute < 10 ? `0${data.start.minute}` : data.start.minute}`;
    }

    let end = null;
    if (typeof data.end === 'string') {
      end = data.end;
    } else if (typeof data?.end?.hour === 'number' && typeof data?.end?.minute) {
      end = `${data.end.hour < 10 ? `0${data.end.hour}` : data.end.hour}:${data.end.minute < 10 ? `0${data.end.minute}` : data.end.minute}`;
    }

    let breakHours = null;
    if (typeof data.breakHours === 'string') {
      breakHours = data.breakHours;
    } else if (typeof data?.breakHours?.hour === 'number' && typeof data?.breakHours?.minute) {
      breakHours = `${data.breakHours.hour < 10 ? `0${data.breakHours.hour}` : data.breakHours.minute}:${data.breakHours.minute < 10 ? `0${data.breakHours.minute}` : data.breakHours.minute}`;
    }

    const res = await updateDateDeliveryRound(dispatch, {
      _id: day._id,
      ...data,
      start,
      end,
      breakHours,
    });
    if (res.status === 200) {
      setSelectedDate(res.data.day);
      handleClose();
    }
  };

  const isDisabled = useMemo(() => {
    let disabled = step === 1 && !getValues('start');
    if (step === 2 && !getValues('end')) disabled = true;
    if (step === 3 && !getValues('breakHours')) disabled = true;
    return disabled;
  }, [
    step,
    watchStart,
    watchEnd,
    watchBreakHours,
  ]);

  return (
    <div className={styles.todayForm}>
      <div className={styles.close} onClick={() => handleClose()}>
        <IoIosAdd size={33} />
      </div>
      <div className={styles.today}>
        <p>Aujourd&apos;hui</p>
      </div>
      <div className={styles.step}>
        <div className={step === 1 ? `${styles.dot} ${styles.selected}` : styles.dot} />
        <div className={step === 2 ? `${styles.dot} ${styles.selected}` : styles.dot} />
        <div className={step === 3 ? `${styles.dot} ${styles.selected}` : styles.dot} />
        <div className={step === 4 ? `${styles.dot} ${styles.selected}` : styles.dot} />
      </div>
      <div className={styles.content}>
        {step === 1 && (
          <>
            <h3>Heure de début</h3>
            <h2>
              À quelle heure avez-vous commencé à travailler ?
            </h2>
            <TimeKeeperInput control={control} name="start" />
          </>
        )}
        {step === 2 && (
          <>
            <h3>Heure de fin</h3>
            <h2>
              À quelle heure avez-vous terminé de travailler ?
            </h2>
            <TimeKeeperInput control={control} name="end" />
          </>
        )}
        {step === 3 && (
          <>
            <h3>Temps de pause</h3>
            <h2>
              Combien de temps de pause avez-vous pris ?
            </h2>
            <TimeKeeperInput control={control} name="breakHours" />
          </>
        )}
        {step === 4 && (
          <>
            <h3>Cible réalisée</h3>
            <h2>
              Combien de livraisons avez-vous effectuées ?
            </h2>
            <div className={styles.target}>
              <InputNumber
                control={control}
                name="target"
              />
            </div>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <NavLink onClick={() => handleClose()}>
          <IoCalendarOutline />
        </NavLink>
        <NavLink
          className={!isDisabled ? styles.active : ''}
          onClick={() => {
            if (step === 4) {
              handleSubmit(onSubmit)();
            } else if (!isDisabled) {
              setStep((state) => state + 1);
            }
          }}
        >
          {(isDisabled && !isLoading)
            ? <FiChevronRight />
            : <BsCheck />
          }
          {isLoading && (
            <div className={styles.loader}>
              <Loader className="secondary" />
            </div>
          )}
        </NavLink>
      </div>
    </div>
  );
};

const TimeKeeperInput = ({ name, control }) => {
  const { field: { onChange, value } } = useController({
    name,
    control,
  });

  return (
    <div className={!value ? 'timekeeper noValue' : 'timekeeper'}>
      <TimeKeeper
        time={typeof value === 'string' ? value : (value?.formatted24 || null)}
        onChange={(newTime) => onChange(newTime)}
        hour24Mode
      />
    </div>
  );
};

export default DeliveryRoundTodayForm;
