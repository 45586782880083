import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DELIVERY_ROUND_GET } from '../../actions/types';
import { getDeliveryRoundByDay } from '../../actions/deliveryRounds';
import Loader from '../../components/Loader';
import BottomNav from '../../components/BottomNav';
import NotificationsBell from '../../components/NotificationsBell';
import logo from '../../assets/images/logo-white.svg';
import truck from '../../assets/images/truck.svg';
import clock from '../../assets/images/clock.png';

import styles from './home.module.scss';

const Home = () => {
  const dispatch = useDispatch();
  const { authReducer, deliveryRoundReducer } = useSelector((store) => store);
  const { user } = authReducer;
  const { deliveryRound, isLoading } = deliveryRoundReducer;

  useEffect(() => {
    const date = format(new Date(), 'dd-MM-yyyy', { locale: fr });
    getDeliveryRoundByDay(dispatch, date);
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.containerLogo}>
            <img src={logo} alt="logo" />
            <div className={styles.notifications}>
              <NotificationsBell color="#FFFFFF" />
            </div>
          </div>
          <h1>Bonjour !<br />{user.profile.firstName} {user.profile.lastName}</h1>
          <div className={styles.deliveryRound}>
            {isLoading.includes(DELIVERY_ROUND_GET) ? (
              <div className={styles.loader}><Loader className="secondary" /></div>
            ) : (
              <>
                <h3>Tournées en cours</h3>
                <div className={styles.col}>
                  <p>Code</p>
                  <p>
                    {deliveryRound?.code || '---'}
                    {deliveryRound && (
                      <span
                        className={styles.color}
                        style={{ backgroundColor: deliveryRound.color }}
                      />
                    )}
                  </p>

                </div>
                <div className={styles.col}>
                  <p>Secteur</p>
                  <p>{deliveryRound?.sector || '---'}</p>
                </div>
                <div className={styles.col}>
                  <p>Cible</p>
                  <p>{deliveryRound?.target || '---'}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={truck} alt="camion" />
          <NavLink to="/timetable" className={styles.clock}>
            <h2>
              Déclarer <br />
              vos horaires
            </h2>
            <img
              src={clock}
              alt="déclarer vos horaires"
            />
          </NavLink>
        </div>
        <BottomNav />
      </div>
    </div>
  );
};

export default Home;
