import React, { useEffect, useRef } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { IoIosAdd } from 'react-icons/io';
import styles from './file-upload.module.scss';

const fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

const FileUploaderComponent = ({
  label = '',
  buttonText = '',
  handleChangeFile,
  required = false,
  hideRequired = false,
  types = null,
  icon,
  file,
  files,
  multiple = false,
  disabled,
  className = 'primary',
}) => {
  const dropZoneRef = useRef();
  const prevElementTrigger = useRef();

  useEffect(() => {
    if (files && file) {
      console.error('FileUploader Component can\'t have property files and file');
    }
  }, []);

  useEffect(() => {
    dropZoneRef.current.parentNode.onclick = (e) => {
      if (e.target.id === 'remove') {
        return;
      }
      e.stopPropagation();
      if (e.target.tagName === 'BUTTON') {
        prevElementTrigger.current = e.target.tagName;
      } else {
        if (prevElementTrigger.current !== 'BUTTON') {
          e.preventDefault();
        }
        prevElementTrigger.current = null;
      }
    };
  }, []);

  return (
    <div>
      <FileUploader
        handleChange={(value) => {
          if (disabled) return;
          handleChangeFile(value);
        }}
        name="file"
        label={label}
        types={types || fileTypes}
        classes={styles.inputFile}
        hoverTitle={' '}
        onTypeError={(e) => console.log(e)}
        onSizeError={(e) => console.log(e)}
        multiple={multiple}
      >
        <div
          ref={dropZoneRef}
          className={styles.dropZone}
        >
          {label && (
            <div>
              <label>{label}{(!hideRequired && !required) && <span> (facultatif)</span>}</label>
            </div>
          )}
          <div className={styles.containerButton}>
            <button
              className={`${className}${file ? ` ${styles.withFile}` : ''}${disabled ? ` ${styles.disabled}` : ''}`}
            >
              {icon && <span className="icon">{icon}</span>}
              {!multiple
                ? <span className={styles.filesName}>{file ? `../${file.name}` : buttonText}</span>
                : (
                  <span className={styles.filesName}>
                    {files.length > 0 ? (
                      Array.from(files).map((f) => (`../${f.name}`)).join(', ')
                    ) : buttonText}
                  </span>
                )
              }
            </button>

            {(file || files?.length > 0) && (
              <div id="remove" className={styles.removeFile} onClick={() => handleChangeFile(multiple ? [] : null)}>
                <IoIosAdd size="24px" />
              </div>
            )}
          </div>
        </div>
      </FileUploader>
    </div>
  );
};

export default FileUploaderComponent;
