import React from 'react';
import ProfileForm from '../../../components/form/ProfileForm';
import styles from './edit.module.scss';

const Edit = () => (
  <div className={styles.edit}>
    <ProfileForm />
  </div>
);

export default Edit;
