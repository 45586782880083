import {
  getData,
  putData,
} from './index';

import {
  NOTIFICATIONS_GET,
  NOTIFICATION_PUT,
  NOTIFICATION_LOADING,
  NOTIFICATION_ERROR,
} from './types';

const getNotifications = async (dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/notifications${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: NOTIFICATION_LOADING,
    payload: NOTIFICATIONS_GET,
  });
  const response = await getData(NOTIFICATION_ERROR, url, dispatch, true);
  if (response.data?.notifications) {
    dispatch({
      type: NOTIFICATIONS_GET,
      payload: response.data,
    });
  }
};

const updateNotification = async (dispatch, data) => {
  const { _id } = data;
  const url = `/notifications/read/${_id}`;
  dispatch({
    type: NOTIFICATION_LOADING,
    payload: `${NOTIFICATION_PUT}-${_id}`,
  });
  const response = await putData(NOTIFICATION_ERROR, url, dispatch, {}, true);
  if (response.data?.notification) {
    dispatch({
      type: NOTIFICATION_PUT,
      payload: response.data,
    });
  }
  return response;
};

export {
  getNotifications,
  updateNotification,
};
