// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_PUT_LICENSE = 'AUTH_PUT_LICENSE';
export const AUTH_SET_AGENCY = 'AUTH_SET_AGENCY';
export const AUTH_UPDATE_PROFILE_PICTURE = 'AUTH_UPDATE_PROFILE_PICTURE';
export const AUTH_DELETE_PROFILE_PICTURE = 'AUTH_DELETE_PROFILE_PICTURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';

// ACTIONS DELIVERY_ROUNDS
export const DELIVERY_ROUND_LOADING = 'DELIVERY_ROUND_LOADING';
export const DELIVERY_ROUND_ERROR = 'DELIVERY_ROUND_ERROR';
export const DELIVERY_ROUND_PUT_DATE = 'DELIVERY_ROUND_PUT_DATE';
export const DELIVERY_ROUND_GET_DATES_BY_MONTH = 'DELIVERY_ROUND_GET_DATES_BY_MONTH';
export const DELIVERY_ROUND_GET = 'DELIVERY_ROUND_GET';

// ACTIONS NOTIFICATIONS

export const NOTIFICATIONS_GET = 'NOTIFICATIONS_GET';
export const NOTIFICATION_PUT = 'NOTIFICATION_PUT';
export const NOTIFICATION_LOADING = 'NOTIFICATION_LOADING';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
